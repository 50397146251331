import React from "react"
import { Helmet } from "react-helmet"
import { graphql, Link } from "gatsby"
import { Layout } from "../../components/layout"
import { Categorie } from "../../data/formations/type"
import { FormationHeadGroup, categorieToStrPl } from "../../data/types/formations"
import { FormationHead } from "../../components/formations/FormationHead"

interface Props {
  data: {
    allFormations: {
      totalCount: number
      distinct: Categorie[]
      group: FormationHeadGroup[]
    }
  }
}

const ORDER = {
  CF: 0,
  CS: 1,
  CA: 2,
}

const Stages: React.FC<Props> = ({ data }) => (
  <Layout>
    <Helmet title="Stages" />
    <div className="container">
      <div className="row mt-3">
        <div className="col my-3">
          <h1>Stages</h1>

          {/* <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title">Guide des formations et stages</h5>
              <p className="card-text">
                Téléchargez le guide des formations et stages proposées par le Comité Régional de Tir à l&apos;Arc du Grand Est pour la saison
                2021/2022.
              </p>
              <p className="card-text">
                <a
                  className="btn btn-outline-primary btn-lg"
                  href="/formations/guide-formations-2021-2022-16-07-2021.pdf"
                  role="button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Guide des formations et stages 2021/2022
                </a>
              </p>
            </div>
          </div> */}

          <div className="formations-container">
            {data.allFormations.group.filter(g => g.fieldValue == 'CS').sort((g1, g2) => ORDER[g1.fieldValue] - ORDER[g2.fieldValue]).map(group => (
              <div className="formations-group-container">
                <h3>
                  {categorieToStrPl(group.fieldValue)} <span className="badge badge-pill badge-secondary">{group.totalCount}</span>
                </h3>

                {/* {group.fieldValue === "CS" ? (
                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col">
                          <h5 className="card-title">
                            <small>
                              <i>CS1</i>
                            </small>{" "}
                            Stages détection 2021-2022
                          </h5>
                          <p className="card-text">
                            <span className="badge badge-secondary">de Poussin à Junior</span>
                          </p>
                        </div>
                        <div className="col-auto">
                          <Link className="btn btn-primary" to="/formations/detection-2021-2022/">
                            <span className="d-none d-md-inline">Plus de détails</span>
                            <span className="icon-chevron-right" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )} */}

                {group.nodes.map(formation => (
                  <FormationHead formation={formation} />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Stages

export const pageQuery = graphql`
  query AllStagesHeads {
    allFormations(sort: { fields: [reference], order: [ASC] }) {
      totalCount
      distinct(field: categorie)
      group(field: categorie) {
        totalCount
        fieldValue
        nodes {
          id
          url
          date(formatString: "DD/MM/YYYY", locale: "FR")
          titre
          reference
          categorie
          tags
          categories
          armes
          diplome
          age
        }
      }
    }
  }
`
